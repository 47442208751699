import BaseModel from './base.model';
import ConditionModel from './condition.model';
import { STATUS_KEY_LIST, STATUS_VALUE_LIST } from '../constants/clients';

class ClientModel extends BaseModel {
	constructor(data) {
		super();
		data = data || {};

		this.id = data.id || '';
		this.auth = data.auth || {};
		this.name = data.name || '';
		this.group = data.group || '';
		this.index = data.index || 0;
		this.quota = data.quota || 0;
		this.quotaMultiplier = data.quota_multiplier ?? 1;
		this.request = data.request || 0;
		this.comment = data.comment;
		this.replace = data.replace || 0;
		this.baseUrl = data.baseUrl || '';
		this.garbageShare = data.garbage_share;
		this.leadCount = data.leadCount ?? 0;
		this.createdAt = this.formatDate(data.created_at);
		this.hasResent = data.hasResent || false;
		this.timeFrames = data.timeFrames || [];
		this.geolocation = data.geolocation || '';
		this.useAutologin = data.use_autologin ?? data.useAutologin ?? true;
		this.sendLeadCount = data.sendLeadCount || 0;
		this.hasError = data.has_error || false;
		this.resendRule = data.resend_rule ?? 1;
		this.type = data.type || '';
		this.totalCap = data.total_cap || false;
		this.subGroupCap = data.sub_group_cap || false;
		this.groupCap = data.group_cap || false;
		this.countriesCap = data.countries_cap || false;
		this.conditionUsers = data.conditionUsers || [];
		this.conditionGeolocation = data.conditionGeolocation || [];
		this.conditionCountries = data.conditionCountries || [];
		this.conditions =
			Array.isArray(data.conditions) &&
			data.conditions.map(condition => new ConditionModel(condition));

		this.status = data.status;
	}

	get statusValue() {
		switch (this.status) {
			case STATUS_KEY_LIST.HIDE:
				return STATUS_VALUE_LIST[STATUS_KEY_LIST.HIDE];
			case STATUS_KEY_LIST.STOPPED:
				return STATUS_VALUE_LIST[STATUS_KEY_LIST.STOPPED];
			case STATUS_KEY_LIST.PROCESSING:
				return STATUS_VALUE_LIST[STATUS_KEY_LIST.PROCESSING];
			default:
				return 'undefined';
		}
	}

	addCondition(condition = {}) {
		this.conditions.push(new ConditionModel(condition));
	}
}

export default ClientModel;
