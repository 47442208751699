import React from 'react';
import { connect } from 'react-redux';

import userLogo from '../../dist/img/user1.jpg';
import {
	LOCAL_GROUPS,
	LOCAL_BILLING,
	LOCAL_CARDS_LIST,
	LOCAL_DASHBOARD,
	LOCAL_LEADS_LIST,
	LOCAL_USERS_LIST,
	LOCAL_DOUBLE_LIST,
	LOCAL_BLACKLIST_IP,
	LOCAL_CLIENTS_LIST,
	LOCAL_DOMAINS_LIST,
	LOCAL_LOGS_LIST,
	LOCAL_LANDINGS_LIST,
	LOCAL_STATISTICS_CPL,
	LOCAL_SUB_DOMAINS_LIST,
	LOCAL_FORM_LIST,
	LOCAL_AUTO_RESEND_LIST,
	LOCAL_PRE_LANDINGS_LIST,
	LOCAL_DEMONETIZATIONS_LIST,
	LOCAL_POST_LANDINGS_LIST,
	LOCAL_APPS_LIST,
	LOCAL_STATISTICS_GROUPS,
	LOCAL_STATISTICS_GENERAL,
	LOCAL_STATISTICS_DEPOSITS_GEOLOCATIONS,
	LOCAL_STATISTICS_DEPOSITS_USERS,
	LOCAL_STATISTICS_DEPOSITS_LINKS,
	LOCAL_STATISTICS_CALCULATIONS_SALARY,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_COUNTRIES,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_GEOLOCATIONS,
	LOCAL_STATISTICS_PAYMENT_TYPE_CPL,
	LOCAL_STATISTICS_DEPOSITS_LANDINGS,
	LOCAL_STATISTICS_DEPOSITS_TEAM_LEADS,
	LOCAL_STATISTICS_DEPOSITS_PRE_LANDINGS,
	LOCAL_STATISTICS_DEPOSITS_COUNTRIES,
	LOCAL_STATUSES_LIST,
	LOCAL_GEO_LIST,
	LOCAL_STATISTICS_CLIENT_HISTORY,
	LOCAL_COUNTRIES_BLACKLIST_LIST,
	LOCAL_LEAD_ARCHIVE,
	LOCAL_STATISTICS_DEPOSITS_CLIENTS_UNIFIED_STATUS,
	LOCAL_OFFICES_EXPENSES,
	LOCAL_OFFICES_EXPENSES_TECH_SPEND,
	LOCAL_STATISTICS_DEPOSITS_CONSTRUCTOR,
	LOCAL_STATISTICS_DEPOSITS_GEO,
	LOCAL_CPL_RULE_LIST,
	LOCAL_TOTAL_CAP_LIST,
	LOCAL_BINOMS_LIST,
	LOCAL_SUB_GROUP_CAP_LIST,
	LOCAL_COUNTRIES_LIMIT_LIST,
	LOCAL_CURRENCY_LIST,
	LOCAL_REDIRECT_DOMAINS_LIST,
	LOCAL_GROUP_CAP_LIST,
	LOCAL_STATUSES_NEW_STATUS,
	LOCAL_REDIRECT_SUB_DOMAINS_LIST,
	LOCAL_BLACKLIST_CONTENT_URLS_LIST,
	LOCAL_SNAPSHOTS_LIST,
} from '../../constants/urls';
import DropDownMenuItem from './DropDownMenuItem';
import MenuItem from './MenuItem';
import {
	ADMIN,
	ANALYTIC,
	MARKUPER,
	AFFILIATE,
	MANAGER,
	WORKER,
	TEAM_LEAD,
	ACCOUNTANT,
	SUPPORT,
	TECH_ADMIN,
	ADMIN_CUT,
} from '../../constants/roles';

const bodyDOMElement = document.body;

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.statisticItems = [];
		this.financeItems = [];
		this.trafficItems = [];
		this.linksItems = [];
		this.funnelsItems = [];
		this.blackListItems = [];
		this.capItems = [];
		this.statusItems = [];
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MANAGER,
			TEAM_LEAD,
			ANALYTIC,
			SUPPORT,
			TECH_ADMIN,
		]) &&
			this.statisticItems.push({
				title: 'General',
				to: LOCAL_STATISTICS_GENERAL,
				hide: false,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MANAGER,
			TEAM_LEAD,
			ANALYTIC,
			ACCOUNTANT,
			TECH_ADMIN,
			WORKER,
		]) && this.statisticItems.push({ title: 'CPL', to: LOCAL_STATISTICS_CPL });
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MANAGER,
			TEAM_LEAD,
			WORKER,
			SUPPORT,
			TECH_ADMIN,
			ACCOUNTANT,
		]) &&
			this.statisticItems.push({
				title: 'Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_USERS,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MANAGER,
			TEAM_LEAD,
			WORKER,
			SUPPORT,
			TECH_ADMIN,
			ACCOUNTANT,
		]) &&
			this.statisticItems.push({
				title: 'Deposits (GEO)',
				to: LOCAL_STATISTICS_DEPOSITS_GEO,
			});
		this.checkRole([ADMIN, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Clients Deposits (Countries)',
				to: LOCAL_STATISTICS_DEPOSITS_CLIENTS_COUNTRIES,
			});
		this.checkRole([ADMIN, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Clients Deposits (Landings)',
				to: LOCAL_STATISTICS_DEPOSITS_CLIENTS_GEOLOCATIONS,
			});
		this.checkRole([ADMIN, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Clients Deposits (Unified Status)',
				to: LOCAL_STATISTICS_DEPOSITS_CLIENTS_UNIFIED_STATUS,
			});
		this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Payment Type CPL',
				to: LOCAL_STATISTICS_PAYMENT_TYPE_CPL,
			});
		this.checkRole([ADMIN, ADMIN_CUT, SUPPORT]) &&
			this.statisticItems.push({
				title: 'Deposit Constructor',
				to: LOCAL_STATISTICS_DEPOSITS_CONSTRUCTOR,
			});
		this.checkRole([ADMIN, ANALYTIC, MANAGER, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Landings Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_LANDINGS,
			});
		this.checkRole([ADMIN, MANAGER, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Geolocations Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_COUNTRIES,
			});
		this.checkRole([ADMIN, MANAGER, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Countries Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_GEOLOCATIONS,
			});
		this.checkRole([ADMIN, MANAGER, SUPPORT, TECH_ADMIN]) &&
			this.statisticItems.push({
				title: 'Team Lead Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_TEAM_LEADS,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			ANALYTIC,
			TEAM_LEAD,
			MANAGER,
			SUPPORT,
			TECH_ADMIN,
			TEAM_LEAD,
			WORKER,
		]) &&
			this.statisticItems.push({
				title: 'Links Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_LINKS,
			});
		this.checkRole([
			ADMIN,
			ANALYTIC,
			// ADMIN_CUT,
			MANAGER,
			SUPPORT,
			TECH_ADMIN,
		]) &&
			this.statisticItems.push({
				title: 'Pre-landings Deposits',
				to: LOCAL_STATISTICS_DEPOSITS_PRE_LANDINGS,
			});
		this.checkRole([ADMIN, SUPPORT, ACCOUNTANT]) &&
			this.statisticItems.push({
				title: 'Calculations Salary',
				to: LOCAL_STATISTICS_CALCULATIONS_SALARY,
			});
		this.checkRole([ADMIN, SUPPORT]) &&
			this.statisticItems.push({
				title: 'Groups',
				to: LOCAL_STATISTICS_GROUPS,
			});
		this.checkRole([ADMIN]) &&
			this.statisticItems.push({
				title: 'Client Fails',
				to: LOCAL_STATISTICS_CLIENT_HISTORY,
			});
		this.checkRole([ADMIN, TECH_ADMIN, ACCOUNTANT]) &&
			this.statisticItems.push({
				title: 'Tech Expense',
				to: LOCAL_OFFICES_EXPENSES_TECH_SPEND,
			});

		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			ACCOUNTANT,
			WORKER,
			TEAM_LEAD,
			TECH_ADMIN,
		]) && this.financeItems.push({ title: 'Billings', to: LOCAL_BILLING });
		this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN]) &&
			this.financeItems.push({
				title: 'Offices Expenses',
				to: LOCAL_OFFICES_EXPENSES,
			});

		this.checkRole([ADMIN, ACCOUNTANT, TECH_ADMIN]) &&
			this.financeItems.push({ title: 'Cards', to: LOCAL_CARDS_LIST });

		this.checkRole([ADMIN]) &&
			this.financeItems.push({ title: 'Currencies', to: LOCAL_CURRENCY_LIST });

		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			TEAM_LEAD,
			WORKER,
			ANALYTIC,
			MANAGER,
			AFFILIATE,
			SUPPORT,
			TECH_ADMIN,
			ACCOUNTANT,
		]) && this.trafficItems.push({ title: 'Leads', to: LOCAL_LEADS_LIST });
		this.checkRole([ADMIN]) &&
			this.trafficItems.push({ title: 'Archive', to: LOCAL_LEAD_ARCHIVE });
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			AFFILIATE,
			SUPPORT,
			TECH_ADMIN,
		]) && this.trafficItems.push({ title: 'Doubles', to: LOCAL_DOUBLE_LIST });

		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) && this.linksItems.push({ title: 'Domains', to: LOCAL_DOMAINS_LIST });
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) &&
			this.linksItems.push({
				title: 'Sub-Domains',
				to: LOCAL_SUB_DOMAINS_LIST,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) && this.linksItems.push({ title: 'Apps', to: LOCAL_APPS_LIST });
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) &&
			this.linksItems.push({
				title: 'Redirects',
				to: LOCAL_BINOMS_LIST,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) &&
			this.linksItems.push({
				title: 'Redirect Domains',
				to: LOCAL_REDIRECT_DOMAINS_LIST,
			});
		this.checkRole([
			ADMIN,
			ADMIN_CUT,
			MARKUPER,
			TEAM_LEAD,
			WORKER,
			MANAGER,
			TECH_ADMIN,
		]) &&
			this.linksItems.push({
				title: 'Redirect Sub Domains',
				to: LOCAL_REDIRECT_SUB_DOMAINS_LIST,
			});

		this.checkRole([ADMIN, ADMIN_CUT, MARKUPER, TECH_ADMIN, SUPPORT]) &&
			this.funnelsItems.push({ title: 'Landings', to: LOCAL_LANDINGS_LIST });
		this.checkRole([ADMIN, ADMIN_CUT, MARKUPER, TECH_ADMIN, SUPPORT]) &&
			this.funnelsItems.push({
				title: 'Pre-Landings',
				to: LOCAL_PRE_LANDINGS_LIST,
			});
		this.checkRole([ADMIN, ADMIN_CUT, MARKUPER, TECH_ADMIN, SUPPORT]) &&
			this.funnelsItems.push({
				title: 'Post-Landings',
				to: LOCAL_POST_LANDINGS_LIST,
			});
		this.checkRole([ADMIN, ADMIN_CUT, MARKUPER, TECH_ADMIN, SUPPORT]) &&
			this.funnelsItems.push({
				title: 'Demonetizations',
				to: LOCAL_DEMONETIZATIONS_LIST,
			});

		this.checkRole([ADMIN, ADMIN_CUT, MANAGER, TECH_ADMIN, MARKUPER]) &&
			this.blackListItems.push({
				title: 'Blacklist IP',
				to: LOCAL_BLACKLIST_IP,
			});
		this.checkRole([ADMIN, ADMIN_CUT, TECH_ADMIN, MARKUPER]) &&
			this.blackListItems.push({
				title: 'Countries Blacklist',
				to: LOCAL_COUNTRIES_BLACKLIST_LIST,
			});
		this.checkRole([ADMIN]) &&
			this.blackListItems.push({
				title: 'Blacklist Content Url',
				to: LOCAL_BLACKLIST_CONTENT_URLS_LIST,
			});

		this.checkRole([ADMIN, SUPPORT]) &&
			this.capItems.push({
				title: 'Total CAP',
				to: LOCAL_TOTAL_CAP_LIST,
			});
		this.checkRole([ADMIN, SUPPORT]) &&
			this.capItems.push({
				title: 'Sub-Group CAP',
				to: LOCAL_SUB_GROUP_CAP_LIST,
			});
		this.checkRole([ADMIN, SUPPORT]) &&
			this.capItems.push({
				title: 'Group CAP',
				to: LOCAL_GROUP_CAP_LIST,
			});
		this.checkRole([ADMIN, SUPPORT]) &&
			this.capItems.push({
				title: 'Countries CAP',
				to: LOCAL_COUNTRIES_LIMIT_LIST,
			});

		this.checkRole([ADMIN, TECH_ADMIN]) &&
			this.statusItems.push({
				title: 'Statuses',
				to: LOCAL_STATUSES_LIST,
			});
		this.checkRole([ADMIN, TECH_ADMIN]) &&
			this.statusItems.push({
				title: 'New Statuses',
				to: LOCAL_STATUSES_NEW_STATUS,
			});
	}

	componentDidMount() {
		bodyDOMElement.addEventListener('click', ({ path }) => {
			if (
				document.documentElement.clientWidth <= 767 &&
				Boolean(
					path.find(item => item?.classList?.contains('content-wrapper')),
				) &&
				bodyDOMElement.classList.contains('sidebar-open')
			) {
				bodyDOMElement.classList.remove('sidebar-open');
			}
		});
	}

	render() {
		return (
			<aside className="main-sidebar">
				<section className="sidebar">
					<div className="user-panel">
						<div className="pull-left image">
							<img src={userLogo} className="img-circle" alt="User Avatar" />
						</div>
						<div className="pull-left info">
							<a href="#/">
								<i className="fa fa-circle text-success" /> Online
							</a>
						</div>
					</div>
					<form action="#/" method="get" className="sidebar-form">
						<div className="input-group">
							<input
								type="text"
								name="q"
								className="form-control"
								placeholder="Search..."
							/>
							<span className="input-group-btn">
								<button
									type="submit"
									name="search"
									id="search-btn"
									className="btn btn-flat"
								>
									<i className="fa fa-search" />
								</button>
							</span>
						</div>
					</form>
					<ul className="sidebar-menu" data-widget="tree">
						<MenuItem
							to={LOCAL_DASHBOARD}
							title="Dashboard"
							iconName="fa-dashboard"
						/>
						<DropDownMenuItem
							title="Traffic"
							iconName="fa-address-book-o"
							hide={this.trafficItems.length === 0}
							items={this.trafficItems}
						/>
						<DropDownMenuItem
							title="Links"
							iconName="fa-external-link"
							hide={this.linksItems.length === 0}
							items={this.linksItems}
						/>
						<DropDownMenuItem
							title="Funnels"
							iconName="fa-file-o"
							hide={this.funnelsItems.length === 0}
							items={this.funnelsItems}
						/>
						<DropDownMenuItem
							title="Black list"
							iconName="fa-money"
							hide={this.blackListItems.length === 0}
							items={this.blackListItems}
						/>
						<DropDownMenuItem
							title="CAP"
							iconName="fa-address-book-o"
							hide={this.capItems.length === 0}
							items={this.capItems}
						/>
						<MenuItem
							title="Geolocations"
							iconName="fa-globe"
							hide={!this.checkRole([ADMIN, TECH_ADMIN])}
							to={LOCAL_GEO_LIST}
						/>
						<MenuItem
							to={LOCAL_AUTO_RESEND_LIST}
							hide={!this.checkRole([ADMIN])}
							title="Auto Resend Tasks"
							iconName="fa-magic"
						/>
						<DropDownMenuItem
							hide={this.statusItems.length === 0}
							items={this.statusItems}
							title="Statuses"
							iconName="fa-list"
						/>
						<MenuItem
							to={LOCAL_FORM_LIST}
							hide={!this.checkRole([ADMIN, MARKUPER])}
							title="Forms"
							iconName=" fa-check-square"
						/>
						<MenuItem
							to={LOCAL_SNAPSHOTS_LIST}
							hide={!this.checkRole([ADMIN])}
							title="Snapshots"
							iconName="fa-camera"
						/>
						<MenuItem
							to={LOCAL_CLIENTS_LIST}
							hide={!this.checkRole([ADMIN, SUPPORT])}
							title="Clients"
							iconName="fa-money"
						/>
						<MenuItem
							to={LOCAL_USERS_LIST}
							hide={
								!this.checkRole([
									ADMIN,
									ADMIN_CUT,
									MARKUPER,
									TEAM_LEAD,
									ANALYTIC,
									MANAGER,
									TECH_ADMIN,
								])
							}
							title="Users"
							iconName="fa-users"
						/>
						<MenuItem
							to={LOCAL_LOGS_LIST}
							hide={!this.checkRole([ADMIN])}
							title="Logs"
							iconName="fa-binoculars"
						/>
						<MenuItem
							to={LOCAL_CPL_RULE_LIST}
							hide={!this.checkRole([ADMIN])}
							title="CPL Rules"
							iconName="fa-calendar-o"
						/>
						<DropDownMenuItem
							title="Finance"
							iconName="fa-money"
							hide={this.financeItems.length === 0}
							items={this.financeItems}
						/>
						<MenuItem
							to={LOCAL_GROUPS}
							hide={!this.checkRole([ADMIN, SUPPORT])}
							title="Groups"
							iconName="fa-object-group"
						/>
						<DropDownMenuItem
							title="Statistics"
							iconName="fa-table"
							hide={this.statisticItems.length === 0}
							items={this.statisticItems}
						/>
					</ul>
				</section>
			</aside>
		);
	}

	checkScope = (scopeName = '', scopeValue = []) => {
		const { access } = this.props;
		return (
			scopeName in access &&
			Boolean(
				scopeValue.filter(value => access[scopeName].includes(value)).length,
			)
		);
	};

	checkRole = (roles = []) => {
		return Boolean(~roles.indexOf(this.props.role));
	};
}

export default connect(
	({ auth: { role, access } }) => ({ role, access }),
	() => ({}),
)(Sidebar);
